import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="panel"
export default class extends Controller {
  connect() {
    let shown = sessionStorage.getItem("panelShown");
    if (shown != "yes") {
      sessionStorage.setItem("panelShown", "yes");
    this.element.classList.remove("hidden");
    }

  }
  close(e) {
    e.preventDefault();
    this.element.classList.add("hidden");
  }
}
